<template>
  <div id="ajuda" class="ma-2">
    <v-row justify="center">
      <v-col sm="8" md="6" lg="6">
          <p class="text-body-2">
            <b>
            Quando o aplicativo verifica se as taxas mudaram?
            </b>
          </p>
          <p class="text-body-2">
            A verificação é feita três vezes ao dia: 09:45hs, 13:45hs e 16:45hs.
          </p>
          <p class="text-body-2">
            <b>
            Qual o gatilho para eu receber um alerta?
            </b>
          </p>
          <p class="text-body-2">
            Para monitoramento de quedas na taxa (menor que), você receberá um alerta se a taxa que você cadastrou é menor que a 
            taxa atual e a taxa caiu em relação a última verificação. Exemplos:<br>
            <br>
            Meu gatilho é <u>menor que</u>: 4.6<br>
            Última verificação (09:45hs): 4.5<br>
            Taxa atual (13:45hs): 4.4<br>
            Recebo Alerta? Sim pois a taxa caiu em relação a última verificação.
            <br><br>
            Meu gatilho é <u>menor que</u>: 4.6<br>
            Última verificação (13:45hs): 4.4<br>
            Taxa atual (16:45hs): 4.5<br>
            Recebo Alerta? Não, pois a taxa subiu em relação a última verificação.<br>
            <br>
            Para monitoramento de altas na taxa (maior que), você receberá um alerta se a taxa que você cadastrou é maior que a 
            taxa atual e a taxa subiu em relação a última verificação. Exemplos:<br>
            <br>
            Meu gatilho é <u>maior que</u>: 4.4<br>
            Última verificação (09:45hs): 4.5<br>
            Taxa atual (13:45hs): 4.6<br>
            Recebo Alerta? Sim pois a taxa subiu em relação a última verificação.
            <br><br>
            Meu gatilho é <u>maior que</u>: 4.4<br>
            Última verificação (13:45hs): 4.6<br>
            Taxa atual (16:45hs): 4.5<br>
            Recebo Alerta? Não, pois a taxa caiu em relação a última verificação.<br>

          </p>
          <p class="text-body-2">
            <b>
            Ainda tem dúvidas? Não deixe de enviar suas perguntas, críticas ou sugestões.
            Sua mensagem será respondida assim que possível:
            </b>
          </p>
          <v-form ref="form">
            <v-textarea label="Mensagem" outlined  :rules="rules" v-model="mensagem"></v-textarea>
          </v-form>
          <v-flex class="text-right">
            <v-btn text color="primary" @click="enviarMensagem()" :loading="sending" :disabled="sending">Enviar</v-btn>
          </v-flex>
      </v-col>
    </v-row>
    <v-snackbar
      top
      v-model="snackbar"
    >
          {{snackbarText}}

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              color="green"
              v-bind="attrs"
              @click="snackbar = false"
            >
              Fechar
            </v-btn>
          </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
        resourceUrl: process.env.VUE_APP_BASE_API_URL + '/api/v1/contato',
        sending: false,
        snackbar: false,
        snackbarText: '',
        mensagem: '',
        rules: [
            value => !!value || 'Requerido.'
        ],
    }
  },

  
  computed: {
    user() {
      return this.$store.state.user;
    },
    jwt() {
      return this.$store.state.user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken();
    },
    headers() {
      return { headers : { authorization : this.jwt } };
    }
  },
  methods: {
    load() {
      console.info('sending...');
    },
    enviarMensagem() {
      if (this.$refs.form.validate()) {
         this.sending = true;
         axios.post(this.resourceUrl, { nome: 'Ajuda', email: this.$store.state.user.attributes["email"], mensagem: this.mensagem })
           .then(response => {
             console.debug(response);
             this.$refs.form.reset()
             this.sending = false;
             this.snackbarText = "Mensagem enviada com sucesso!";
             this.snackbar = true;
         });
       }
    }
  },
  created() {
    //document.title = "Configurações";
  },
  mounted() {
    this.$store.state.pageTitle = 'Ajuda';
  }
}
</script>
<style>

</style>