import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './state'
import vuetify from './plugins/vuetify';

import Amplify from 'aws-amplify';
import "@aws-amplify/ui-vue";
import awsconfig from './aws-exports';

import "@/assets/global.css"
import "@/languages/translations.js"

Amplify.configure(awsconfig);
Vue.config.productionTip = false


new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
