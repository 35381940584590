import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Alertas from '../views/Alertas.vue'
import Titulos from '../views/Titulos.vue'
import Config from '../views/Config.vue'
import Ajuda from '../views/Ajuda.vue'
import Main from '../views/Main.vue'
import Doacao from '../views/Doacao.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/p', component: Main,
    children: [
      { path: 'alertas', component: Alertas },
      { path: 'titulos', component: Titulos },
      { path: 'doacao', component: Doacao },
      { path: 'config', component: Config },
      { path: 'ajuda', component: Ajuda }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
