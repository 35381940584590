<template>
  <div id="topo" class="ma-2">
    <v-row justify="center" v-if="!loading">
      <v-col sm="8" md="6" lg="6">
        <v-simple-table v-if="titulos.length">
            <template v-slot:default>
              <thead>
                  <tr>
                      <th class="text-left">
                          Nome
                      </th>
                      <th class="text-left">
                          Taxa
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="titulo in titulos" :key="titulo.id">
                      <td width="80%">{{ titulo.nome }}</td>
                      <td width="20%">{{ titulo.taxa.toFixed(2) }}%</td>
                  </tr>
              </tbody>
            </template>
        </v-simple-table>
        <v-alert v-if="!titulos.length"
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          Não foi possível buscar a lista de títulos disponíveis no <a href="https://www.tesourodireto.com.br/titulos/precos-e-taxas.htm" target="_blank">site do Tesouro Direto</a>. Tente novamente mais tarde.
        </v-alert>
      </v-col>
    </v-row>
    <!-- <v-row v-if="loading" class="text-center" justify="center">
      <v-col>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row> -->
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
      return {
        loading: false,
        resourceUrl: process.env.VUE_APP_BASE_API_URL+"/api/v1/titulos",
        titulos: []
      }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    jwt() {
      return this.$store.state.user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken();
    },
    headers() {
      return { headers : { authorization : this.jwt } };
    }
  },
  methods: {
    load() {
      if (this.user) {
        this.loading = true;
        this.$vuetify.goTo('#topo', { duration: 0 });
        axios
          .get(this.resourceUrl, this.headers )
          .then(response => {
            this.titulos = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.info(error);
            this.$router.push('/');
          });
      }
    }
  },
  created() {
    //document.title = "Títulos Disponíveis";
  },
  mounted() {
    this.$store.state.pageTitle = 'Títulos Disponíveis';
    this.load();
  },
  watch: {
    user(){
      if (this.user) {
        this.load();
      }
    }
  }
}
</script>