<template>
  <div class="ma-2">
    <v-row justify="center" v-if="!loading">
      <v-col sm="8" md="6" lg="6">
         <v-card>
             <v-card-title>Email</v-card-title>
             <v-card-text>
                Em alguns serviços de email as notificações podem cair na <u>caixa de promoções</u> (Google). 
                Utilize esse teste para simular uma notificação, verificar se está recebendo o email corretamente e caso contrário 
                classificá-lo para cair na sua caixa principal.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" :loading="sendingMail" :disabled="sendingMail" @click="enviarNotificacaoEmail()">
                    Enviar Notificação
                </v-btn>
            </v-card-actions>
         </v-card>
      </v-col>
    </v-row>
    <v-row v-if="loading" class="text-center" justify="center">
      <v-col>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
        top
      >
        {{snackbarText}}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="green"
            v-bind="attrs"
            @click="snackbar = false"
          >
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
      return {
        loading: false,
        sendingMail: false,
        snackbar: false,
        snackbarText: '',
        emailNotifyEndpoint: process.env.VUE_APP_BASE_API_URL + "/api/v1/config/notifyemail",
        titulos: []
      }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    jwt() {
      return this.$store.state.user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken();
    },
    headers() {
      return { headers : { authorization : this.jwt } };
    }
  },
  methods: {
    load() {
      console.info('loading...');
    },
    enviarNotificacaoEmail() {
        this.sendingMail = true;
        axios.post(this.emailNotifyEndpoint, {}, this.headers)
        .then(response => {
            console.debug(response);
            this.sendingMail = false;
            this.snackbarText = "Email enviado com sucesso!";
            this.snackbar = true;
        });
    }
  },
  created() {
    //document.title = "Configurações";
  },
  mounted() {
    this.$store.state.pageTitle = 'Configurações';
  }
}
</script>
<style>

.v-card__text {
    width: 90% !important;
}

</style>