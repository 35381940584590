/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "sa-east-1",
    "aws_cognito_identity_pool_id": "sa-east-1:41a2186c-c544-4fc5-bff9-2a843eae043f",
    "aws_cognito_region": "sa-east-1",
    "aws_user_pools_id": "sa-east-1_ksCzMpuro",
    "aws_user_pools_web_client_id": "7mbtnm9p2q5n493vkqg4d1c118",
    "oauth": {}
};


export default awsmobile;
