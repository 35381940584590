<template>
  <v-app>
    <v-app-bar fixed app>
      <v-spacer></v-spacer>

      <v-toolbar-items>

        <v-btn text @click="$vuetify.goTo('#inicio')">
          <v-icon left>
            mdi-home-outline
          </v-icon>
          Inicio
        </v-btn>

        <v-divider vertical></v-divider>

        <v-btn text @click="$vuetify.goTo('#contato')">
          <v-icon left>
            mdi-email-outline
          </v-icon>
          Contato
        </v-btn>

        <v-divider vertical></v-divider>

        <v-btn text to="/p/titulos">
          <v-icon left>
            mdi-login-variant
          </v-icon>
          Entrar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <div id='inicio' class="blue darken-4 white--text">
        <v-container>
          <v-row class="text-center" justify="center">
            <v-col class="ma-2" cols="12" lg="8">
              <v-icon class="text-h1 yellow--text ma-3">mdi-chart-areaspline</v-icon>
              <h3 class="text-h3 mb-6">Alerta Tesouro Direto</h3>
              <p class="text-body-1 ma-3 mb-6">As taxas dos títulos do Tesouro Direto variam todos os dias e é possível ganhar <u>muito dinheiro</u> com essas variações. Esse movimento é 
                chamado de <u>marcação a mercado</u>. No entanto, é necessário fazer uma <u>gestão ativa</u>, ou seja, comprar quando as taxas estão altas e vender quando estão baixas. 
                Esse aplicativo te ajudará a monitorar o valor dessas taxas, enviando <u>alertas por email</u> quando elas chegarem no valor que você deseja.
              </p>
              <v-btn class="ma-3" color="white" outlined to="/p/titulos" width="170">
                <v-icon left>
                  mdi-login-variant
                </v-icon>
                Entrar
              </v-btn>
              <v-btn class="ma-3" color="white" outlined @click="criarConta()" width="170">
                <v-icon left>
                  mdi-account-plus-outline
                </v-icon>
                Criar Conta
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div id="recursos">
        <v-container>
          <v-row class="text-center" justify="center">
            <v-col class="ma-2" cols="12" lg="3">
              <v-icon class="text-h1 ma-3">mdi-gesture-double-tap</v-icon>
              <p class="text-body-1 ma-3">Chega de acessar manualmente o site do Tesouro Direto ou da sua corretora para conferir 
                se o valor das taxas estão atrativas.
              </p>
            </v-col>
            <v-col class="ma-2" cols="12" lg="3">
              <v-icon class="text-h1 ma-3">mdi-bullhorn-outline</v-icon>
              <p class="text-body-1 ma-3">Receba alertas por email quando o valor das taxas do Tesouro Direto chegarem no valor que você deseja.
              </p>
            </v-col>
            <v-col class="ma-2" cols="12" lg="3">
              <v-icon class="text-h1 ma-3">mdi-currency-usd</v-icon>
              <p class="text-body-1 ma-3">Ganhe dinheiro comprando títulos do Tesouro Direto quando o valor das taxas sobem e vendendo quando elas caem.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-divider></v-divider>
      <div id="contato" >
        <v-container>
          <v-row class="text-center" justify="center">
            <v-col class="ma-2" cols="12" lg="8">
              <h3 class="text-h3">Contato</h3>
              <p class="text-body-1 ma-6">Entre em contato e envie suas dúvidas, críticas ou sugestões.</p>
              <v-form ref="form">
                <v-text-field label="Nome" outlined :rules="rules" v-model="nome"></v-text-field>
                <v-text-field label="Email" outlined  :rules="rules" v-model="email"></v-text-field>
                <v-textarea label="Mensagem" outlined  :rules="rules" v-model="mensagem"></v-textarea>
              </v-form>
              <v-btn class="ma-3" outlined @click="enviarContato()" :loading="loading" :disabled="loading">
                <v-icon left>
                  mdi-email-send-outline
                </v-icon>
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div id='rodape' class="blue darken-4 white--text">
        <v-container>
          <v-row class="text-center" justify="center">
            <v-col >
              <p class="text-caption">
                Copyright &copy; 2020. Todos os direitos reservados. Desenvolvido por Rodrigo Lira. 
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-snackbar
        v-model="snackbar"
        top
      >
        {{snackbarText}}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="green"
            v-bind="attrs"
            @click="snackbar = false"
          >
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>  
</template>

<script>
import axios from 'axios'
export default {
  name: 'Home',
  components: {
    //HelloWorld
  },
  data() {
    return {
      resourceUrl: process.env.VUE_APP_BASE_API_URL + '/api/v1/contato',
      loading: false,
      snackbar: false,
      snackbarText: '',
      nome: '',
      email: '',
      mensagem: '',
      rules: [
        value => !!value || 'Requerido.'
      ],
    }
  },
  created() {
    //document.title = "Alerta Tesouro Direto";
  },
  methods: {
    enviarContato() {
      if (this.$refs.form.validate()) {
         this.loading = true;
         axios.post(this.resourceUrl, { nome: this.nome, email: this.email, mensagem: this.mensagem })
           .then(response => {
             console.debug(response);
             this.$refs.form.reset()
             this.loading = false;
             this.snackbarText = "Mensagem enviada com sucesso!";
             this.snackbar = true;
         });
       }
    },
    criarConta() {
      this.$router.push( { path: '/p/titulos', query: { authScreen : "signup" } } )
    }
  }  
}
</script>



