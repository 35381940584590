<template>
  <div class="ma-2">
    <v-row v-if="!loading" dense justify="center">
      <v-col sm="8" md="6" lg="6" v-if="alertas.length">
        <v-simple-table dense>
            <template v-slot:default>
              <thead>
                  <tr>
                      <th class="text-left">
                          Nome
                      </th>
                      <th class="text-left">
                          Menor que
                      </th>
                      <th class="text-left">
                          Maior que
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(alerta, idx) in alertas" :key="alerta.id">
                      <td width="50%">{{ alerta.nomeTitulo }}</td>
                      <td width="25%"><v-text-field :rules="taxaRules" :placeholder="alertas[idx].taxaAtual.toFixed(2)" v-model.number="alertas[idx].menorQue"/></td>
                      <td width="25%"><v-text-field :rules="taxaRules" :placeholder="alertas[idx].taxaAtual.toFixed(2)" v-model.number="alertas[idx].maiorQue"/></td>
                  </tr>
              </tbody>
            </template>
        </v-simple-table>
        <v-divider></v-divider>
        <br>
        <v-flex class="text-right">
          <v-btn text color="primary" @click="cancelar()">Cancelar</v-btn>
          <v-btn text color="primary" @click="salvar()" :loading="saving" :disabled="saving">Salvar</v-btn>
        </v-flex>
      </v-col>
      <v-col sm="8" md="6" lg="6" v-if="!alertas.length">
        <v-alert 
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          Não foi possível buscar a lista de títulos disponíveis no <a href="https://www.tesourodireto.com.br/titulos/precos-e-taxas.htm" target="_blank">site do Tesouro Direto</a>. Tente novamente mais tarde.
        </v-alert>
      </v-col>
    </v-row>
    <!-- <v-row v-if="loading" class="text-center" justify="center">
      <v-col>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row> -->
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      top
      v-model="snackbar"
    >
          {{snackbarText}}

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              color="green"
              v-bind="attrs"
              @click="snackbar = false"
            >
              Fechar
            </v-btn>
          </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Alertas',
  data: function() {
      return {
        resourceUrl: process.env.VUE_APP_BASE_API_URL + "/api/v1/alertas",
        loading : false,
        saving : false,
        snackbar : false,
        snackbarText: "",
        alertas: [],
        taxaRules: [
          value => {
            const pattern = /^[0-9]+(\.[0-9]*)?$/
            return (!value || pattern.test(value)) || 'Inválido.'
          } 
        ],
      }
  },
  created() {
    //document.title = "Alertas";
  },
  mounted() {
    this.$store.state.pageTitle = 'Alertas';
    this.load();    
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    jwt() {
      return this.$store.state.user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken();
    },
    headers() {
      return { headers : { authorization : this.jwt } };
    }
  },
  methods: {
    salvar() {
      //console.info(this.alertas);
      this.saving = true;
      axios.post(this.resourceUrl, this.alertas, this.headers)
        .then(response => {
          console.debug(response);
          this.saving = false;
          this.snackbarText = "Alertas salvos com sucesso!";
          this.snackbar = true;
        });
    },
    load() {
      if (this.user) {
        this.loading = true;
        axios
          .get(this.resourceUrl, this.headers)
          .then(response => {
            this.alertas = response.data.alertas;
            this.loading = false;
          });
      }
    },
    cancelar() {
      this.load();
      this.snackbarText = "Alterações canceladas!";
      this.snackbar = true;
    }    
  },
  watch: {
    user() {
      if (this.user) {
        this.load();
      }
    }
  }
}
</script>

<style>
th,td {
  padding-left: 5px !important;
}
</style>