<template>
  <div class="ma-2">
    <v-row justify="center" v-if="!loading">
      <v-col sm="8" md="6" lg="6">
         <v-card>
             <v-card-title>Pix</v-card-title>
             <v-card-text>
                Manter esse aplicativo funcionando exige tempo, conhecimento técnico e dinheiro. Se você acha ele
                útil e já ganhou dinheiro com o Tesouro Direto, faça uma doação de qualquer valor para mantê-lo
                funcionando.
                <br><br>
                <div class="center-text"><b>QR Code</b></div>

                <img src="../assets/pix-qr-code.png" class="center-img"/>
                <br>
                <div class="center-text"><b>Chave Copia e Cola:</b></div>
                <div class="center-text">699e84d9-6b84-4e4b-a6da-1784c895d0db</div>                
                <br>
            </v-card-text>
         </v-card>
      </v-col>
    </v-row>
    <v-row v-if="loading" class="text-center" justify="center">
      <v-col>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
      return {
        loading: false,
        sendingMail: false,
        snackbar: false,
        snackbarText: '',
        emailNotifyEndpoint: process.env.VUE_APP_BASE_API_URL + "/api/v1/config/notifyemail",
        titulos: []
      }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    jwt() {
      return this.$store.state.user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken();
    },
    headers() {
      return { headers : { authorization : this.jwt } };
    }
  },
  methods: {
    load() {
      console.info('loading...');
    },
    enviarNotificacaoEmail() {
        this.sendingMail = true;
        axios.post(this.emailNotifyEndpoint, {}, this.headers)
        .then(response => {
            console.debug(response);
            this.sendingMail = false;
            this.snackbarText = "Email enviado com sucesso!";
            this.snackbar = true;
        });
    }
  },
  created() {
    //document.title = "Configurações";
  },
  mounted() {
    this.$store.state.pageTitle = 'Configurações';
  }
}
</script>
<style>

.v-card__text {
    width: 100% !important;
}

.center-text {
  text-align: center;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

</style>