import { I18n } from 'aws-amplify';
import { Translations } from "@aws-amplify/ui-components";

I18n.putVocabulariesForLanguage("pt-BR", {
  [Translations.BACK_TO_SIGN_IN] : 'Voltar',
  [Translations.CHANGE_PASSWORD_ACTION] : 'Change',
  [Translations.CHANGE_PASSWORD] : 'Change Password',
  [Translations.CODE_LABEL] : 'Código de verificação',
  [Translations.CODE_PLACEHOLDER] : 'Código recebido por email',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL] : 'Código de Confirmação',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER] : 'Código recebido por email',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT] : 'Confirmar Email',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE] : 'Não recebeu o código?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE] : 'Reenviar',
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT] : 'Confirmar',
  [Translations.CONFIRM_SMS_CODE] : 'Confirm SMS Code',
  [Translations.CONFIRM_TOTP_CODE] : 'Confirm TOTP Code',
  [Translations.CONFIRM] : 'Confirmar',
  [Translations.CREATE_ACCOUNT_TEXT] : 'Criar conta',
  [Translations.EMAIL_LABEL] : 'Email *',
  [Translations.EMAIL_PLACEHOLDER] : 'Digite seu email',
  [Translations.FORGOT_PASSWORD_TEXT] : ' ',
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE] : 'Less than two mfa types available',
  [Translations.NEW_PASSWORD_LABEL] : 'Nova senha',
  [Translations.NEW_PASSWORD_PLACEHOLDER] : 'Digite sua nova senha',
  [Translations.NO_ACCOUNT_TEXT] : 'Não tem conta?',
  [Translations.PASSWORD_LABEL] : 'Senha *',
  [Translations.PASSWORD_PLACEHOLDER] : 'Digite sua senha',
  [Translations.PHONE_LABEL] : 'Phone Number *',
  [Translations.PHONE_PLACEHOLDER] : '(555) 555-1212',
  [Translations.QR_CODE_ALT] : 'qrcode',
  [Translations.RESET_PASSWORD_TEXT] : 'Esqueci minha senha',
  [Translations.RESET_YOUR_PASSWORD] : 'Alterar senha',
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT] : 'Select MFA Type',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT] : 'Verify',
  [Translations.SEND_CODE] : 'Enviar Código',
  [Translations.SUBMIT] : 'Confirmar',
  [Translations.SETUP_TOTP_REQUIRED] : 'TOTP needs to be configured',
  [Translations.SIGN_IN_ACTION] : 'Entrar',
  [Translations.SIGN_IN_HEADER_TEXT] : 'Entrar na sua conta',
  [Translations.SIGN_IN_TEXT] : 'Entrar',
  [Translations.SIGN_IN_WITH_AMAZON] : 'Sign in with Amazon',
  [Translations.SIGN_IN_WITH_AUTH0] : 'Sign in with Auth0',
  [Translations.SIGN_IN_WITH_AWS] : 'Sign in with AWS',
  [Translations.SIGN_IN_WITH_FACEBOOK] : 'Sign in with Facebook',
  [Translations.SIGN_IN_WITH_GOOGLE] : 'Sign in with Google',
  [Translations.SIGN_OUT] : 'Sign Out',
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER] : 'Email',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT] : 'Já tem conta?',
  [Translations.SIGN_UP_HEADER_TEXT] : 'Criar uma nova conta',
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER] : 'Password',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT] : 'Criar Conta',
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER] : 'Username',
  [Translations.SUCCESS_MFA_TYPE] : 'Success! Your MFA Type is now:',
  [Translations.TOTP_HEADER_TEXT] : 'Scan then enter verification code',
  [Translations.TOTP_LABEL] : 'Enter Security Code:',
  [Translations.TOTP_ISSUER] : 'AWSCognito',
  [Translations.TOTP_SETUP_FAILURE] : 'TOTP Setup has failed',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT] : 'Verify Security Token',
  [Translations.TOTP_SUCCESS_MESSAGE] : 'Setup TOTP successfully!',
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME] : 'Failed! Unable to configure MFA at this time',
  [Translations.USERNAME_LABEL] : 'Username *',
  [Translations.USERNAME_PLACEHOLDER] : 'Enter your username',
  [Translations.VERIFY_CONTACT_EMAIL_LABEL] : 'Email',
  [Translations.VERIFY_CONTACT_HEADER_TEXT] : 'Account recovery requires verified contact information',
  [Translations.VERIFY_CONTACT_PHONE_LABEL] : 'Phone Number',
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL] : 'Confirmar',
  [Translations.VERIFY_CONTACT_VERIFY_LABEL] : 'Verify',
  [Translations.ADDRESS_LABEL] : 'Address',
  [Translations.ADDRESS_PLACEHOLDER] : 'Enter your address',
  [Translations.NICKNAME_LABEL] : 'Nickname',
  [Translations.NICKNAME_PLACEHOLDER] : 'Enter your nickname',
  [Translations.BIRTHDATE_LABEL] : 'Birthday',
  [Translations.BIRTHDATE_PLACEHOLDER] : 'Enter your birthday',
  [Translations.PICTURE_LABEL] : 'Picture URL',
  [Translations.PICTURE_PLACEHOLDER] : 'Enter your picture URL',
  [Translations.FAMILY_NAME_LABEL] : 'Family Name',
  [Translations.FAMILY_NAME_PLACEHOLDER] : 'Enter your family name',
  [Translations.PREFERRED_USERNAME_LABEL] : 'Preferred Username',
  [Translations.PREFERRED_USERNAME_PLACEHOLDER] : 'Enter your preferred username',
  [Translations.GENDER_LABEL] : 'Gender',
  [Translations.GENDER_PLACEHOLDER] : 'Enter your gender',
  [Translations.PROFILE_LABEL] : 'Profile URL',
  [Translations.PROFILE_PLACEHOLDER] : 'Enter your profile URL',
  [Translations.GIVEN_NAME_LABEL] : 'First Name',
  [Translations.GIVEN_NAME_PLACEHOLDER] : 'Enter your first name',
  [Translations.ZONEINFO_LABEL] : 'Time zone',
  [Translations.ZONEINFO_PLACEHOLDER] : 'Enter your time zone',
  [Translations.LOCALE_LABEL] : 'Locale',
  [Translations.LOCALE_PLACEHOLDER] : 'Enter your locale',
  [Translations.UPDATED_AT_LABEL] : 'Updated At',
  [Translations.UPDATED_AT_PLACEHOLDER] : 'Enter the time the information was last updated',
  [Translations.MIDDLE_NAME_LABEL] : 'Middle Name',
  [Translations.MIDDLE_NAME_PLACEHOLDER] : 'Enter your middle name',
  [Translations.WEBSITE_LABEL] : 'Website',
  [Translations.WEBSITE_PLACEHOLDER] : 'Enter your website',
  [Translations.NAME_LABEL] : 'Full Name',
  [Translations.NAME_PLACEHOLDER] : 'Enter your full name',
  [Translations.PHOTO_PICKER_TITLE] : 'Picker Title',
  [Translations.PHOTO_PICKER_HINT] : 'Ancillary text or content may occupy this space here',
  [Translations.PHOTO_PICKER_PLACEHOLDER_HINT] : 'Placeholder hint',
  [Translations.PHOTO_PICKER_BUTTON_TEXT] : 'Button',
  [Translations.IMAGE_PICKER_TITLE] : 'Add Profile Photo',
  [Translations.IMAGE_PICKER_HINT] : 'Preview the image before upload',
  [Translations.IMAGE_PICKER_PLACEHOLDER_HINT] : 'Tap to image select',
  [Translations.IMAGE_PICKER_BUTTON_TEXT] : 'Upload',
  [Translations.PICKER_TEXT] : 'Pick a file',
  [Translations.TEXT_FALLBACK_CONTENT] : 'Fallback Content',
  [Translations.CONFIRM_SIGN_UP_FAILED] : 'Confirm Sign Up Failed',
  [Translations.SIGN_UP_FAILED] : 'Sign Up Failed',
  
  //AuthErrorStrings
  [Translations.DEFAULT_MSG] : 'Erro inesperado ao auitentcar',
  [Translations.EMPTY_USERNAME] : 'Nome do usuário não pode ser vazio',
  [Translations.INVALID_USERNAME] : 'The username should either be a string or one of the sign in types',
  [Translations.EMPTY_PASSWORD] : 'Senha não pode ser vazio',
  [Translations.EMPTY_CODE] : 'Código de confirmação não foi preenchido',
  [Translations.SIGN_UP_ERROR] : 'Erro inesperado ao criar conta',
  [Translations.NO_MFA] : 'No valid MFA method provided',
  [Translations.INVALID_MFA] : 'Invalid MFA type',
  [Translations.EMPTY_CHALLENGE] : 'Challenge response cannot be empty',
  [Translations.NO_USER_SESSION] : 'Failed to get the session because the user is empty',
  'User does not exist.': 'Usuário não existe!',
  'Password did not conform with policy: Password not long enough': 'Senha muito curta. Aumente o número de caracteres.',
  'An account with the given email already exists.': 'Já existe uma conta com esse email.',
  '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6': 'Senha deve ter no mínimo 6 caracteres',
  'Invalid verification code provided, please try again.': 'Código de validação inválido.',
  'Incorrect username or password.' : 'Usuário ou senha inválido(s).'
});

/*
export const dict = {
	"pt-BR": {
    'Sign In': 'Anmeldenss',
		'Sign Up': 'Registrieren',
		'Sign Out': 'Abmelden',
		'Sign in to your account': 'Melden Sie sich mit Ihrem Account an',
		Username: 'Benutzername',
		Password: 'Passwort',
		'Enter your username': 'Geben Sie Ihren Benutzernamen ein',
		'Enter your password': 'Geben Sie Ihr Passwort ein',
		'No account? ': 'Kein Account? ',
		'Forgot your password?': 'aaaaa ',
		'Reset password': 'Passwort zurücksetzensssss',
		'User does not exist': 'Usuário não existe.',
		'User already exists': 'Dieser Benutzer existiert bereits',
		'Incorrect username or password':
			'Falscher Benutzername oder falsches Passwort',
		'Invalid password format': 'Ungültiges Passwort-Format',
		'Create account': 'Hier registrieren',
		'Forgot Password': 'Passwort vergessen',
		'Change Password': 'Passwort ändern',
		'New Password': 'Neues Passwort',
		Email: 'Email',
		'Phone Number': 'Telefonnummer',
		'Confirm a Code': 'Code bestätigen',
		'Confirm Sign In': 'Anmeldung bestätigen',
		'Confirm Sign Up': 'Registrierung bestätigen',
		'Back to Sign In': 'Zurück zur Anmeldung',
		'Send Code': 'Code senden',
		Confirm: 'Bestätigen',
		'Resend Code': 'Code erneut senden',
		Submit: 'Abschicken',
		Skip: 'Überspringen',
		Verify: 'Verifizieren',
		'Verify Contact': 'Kontakt verifizieren',
		Code: 'Code',
		'Confirmation Code': 'Bestätigungs-Code',
		'Lost your code? ': 'Code verloren? ',
		'Account recovery requires verified contact information':
			'Zurücksetzen des Account benötigt einen verifizierten Account',
		'Invalid phone number format': `Ungültiges Telefonummern-Format.
            Benutze eine Nummer im Format: +12345678900`,
		'Create Account': 'Account erstellen',
		'Have an account? ': 'Schon registriert? ',
		'Sign in': 'Anmelden',
		'Create a new account': 'Erstelle einen neuen Account',
		'Reset your password': 'Zurücksetzen des Passworts',
		'An account with the given email already exists.':
			'Ein Account mit dieser Email existiert bereits.',
		'Username cannot be empty': 'Benutzername darf nicht leer sein',
		'Password attempts exceeded':
			'Die maximale Anzahl der fehlerhaften Anmeldeversuche wurde erreicht',
	}
};

I18n.putVocabularies(dict);
*/